import * as R from 'ramda'

import * as relatedRecordActions from './relatedRecords.actions'
import * as records from './records.reducer'

interface IParentIdRecord {
  [parentRecordId: string]: records.IRecordState
}
export interface IRelatedRecordsState {
  [record: string]: IParentIdRecord
}

export const reducer = (
  state: IRelatedRecordsState = {},
  action: relatedRecordActions.RelatedRecordActions,
) => {
  if (!records.isRecordAction(action)) {
    return state
  }
  const { record, parentRecordId } = action.payload
  const pId = parentRecordId as string | number
  const parentAction = R.assoc(
    'type',
    relatedRecordActions.recordActionMap[action.type],
    action,
  )
  const recordState = R.pathOr(records.defaultRecordState, [record, pId], state)

  return R.has(action.type, relatedRecordActions.recordActionMap)
    ? R.assocPath(
        [record, pId.toString()],
        records.recordReducer(recordState, parentAction),
        state,
      )
    : state
}
