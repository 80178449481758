import * as R from 'ramda'
import moment, { Moment } from 'moment-timezone'

export const getMoment = (
  date: Moment | Date | number | string | undefined = undefined,
) => {
  if (!date) {
    return moment()
  }
  if (moment.isMoment(date)) {
    return date
  }

  if (R.is(Number, date)) {
    const unix = moment.unix(date as number)
    if (unix.year() > 3000) {
      return moment(date)
    }
    return unix
  }

  return moment(date)
}

export const getEpochNow = () => getMoment().unix()

export const formatDate = (date, format = 'MMMM D, YYYY') =>
  date ? getMoment(date).format(format) : ''

export const formatForDisplay = date => formatDate(date, 'MM/D/YYYY hh:mm A')
