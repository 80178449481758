const parseItem = (item: any, defaultValue: any = false) =>
  item ? JSON.parse(item) : defaultValue

export const getSessionItem = (key: string, defaultValue: any = false) =>
  parseItem(sessionStorage.getItem(key), defaultValue)

export const setSessionItem = (key: string, value: any) =>
  sessionStorage.setItem(key, JSON.stringify(value))

export const removeSessionItem = (key: string) => sessionStorage.removeItem(key)

export const clearAllSessionItems = () => sessionStorage.clear()
