import * as normalizr from 'normalizr'
import { AxiosResponse } from 'axios'
import * as R from 'ramda'

import { convertToInt } from '../../utils'
import * as moduleUtils from './utils'

export const utils = moduleUtils

export interface IRef {
  id: string | number
}

export const getRefId = (item: any, refName: string, fallback: any = null) => {
  const id = R.pathOr(fallback, [refName, 'id'], item)
  return id ? convertToInt(id) : id
}

export interface IModuleApi {
  create: (data: any) => string
  prepareCreateData?: (data: any) => any
  get: (id: string | number, params?: any) => string
  getList: (params?: object | null, parentRecordId?: number | string) => string
  update: (id: string | number, params?: any) => string
  prepareUpdateData?: (data: any) => any
  delete: (id: string | number, params?: any) => string
}

export interface IModule {
  record: string
  responseDataKey?: string | string[]
  noResponseDataKey?: boolean
  getResponseData?: (response: AxiosResponse) => any
  getCreateResponseData?: (response: AxiosResponse) => any
  schema: normalizr.schema.Entity
  getId?: (entity: object) => string
  api: IModuleApi
}
