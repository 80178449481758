import * as R from 'ramda'
import { createSelector } from 'reselect'

import { idSelector, entityListSelector, getIdType } from './entities'
import { ensureArray } from '../../utils/array'
import { recordCustomPropertySelector } from './records'
import { defaultRecordState, IRecordState } from '../ducks/records.reducer'

export const relatedRecordSelector = (
  record: string,
  getParentRecordId: getIdType,
) =>
  createSelector<any, object, object, string | number, IRecordState>(
    R.pathOr(defaultRecordState, ['relatedRecords', record]),
    idSelector(getParentRecordId),
    (records, id) =>
      R.propOr(defaultRecordState, id ? id.toString() : '', records),
  )

export const allRelatedRecordsSelector = (
  record: string,
  parentRecordId: getIdType,
) =>
  createSelector<any, object, IRecordState, object, any[]>(
    relatedRecordSelector(record, parentRecordId),
    entityListSelector(record),
    ({ items = [] }, entities) => items.map(id => entities[id]),
  )

export const relatedRecordPropertySelector = (
  record: string,
  getParentRecordId: getIdType,
  propertyPath: string | number | (string | number)[],
  fallback: any = null,
) =>
  createSelector<any, object, IRecordState, any>(
    relatedRecordSelector(record, getParentRecordId),
    R.pathOr(fallback, ensureArray(propertyPath)),
  )

export const relatedRecordCustomPropertySelector = (
  record: string,
  getParentRecordId: getIdType,
  propertyPath: string | number | (string | number)[],
  fallback: any = null,
) =>
  relatedRecordPropertySelector(
    record,
    getParentRecordId,
    R.prepend('customProperties', ensureArray(propertyPath)),
    fallback,
  )

// Convenience selectors
export const relatedRecordIsLoadingSelector = (
  record: string,
  getParentRecordId: getIdType,
) => relatedRecordPropertySelector(record, getParentRecordId, 'loading', false)

export const fullRelatedRecordsSelector = (
  record: string,
  getParentRecordId: getIdType,
) =>
  createSelector<any, object, any[], IRecordState, IRecordState>(
    allRelatedRecordsSelector(record, getParentRecordId),
    relatedRecordSelector(record, getParentRecordId),
    (items, recordState) => R.assoc('items', items, recordState),
  )

export const searchValueSelector = createSelector<
  any,
  object,
  string,
  string | number,
  any,
  string
>(
  idSelector('record'),
  idSelector('parentId'),
  R.identity,
  (record, parentRecordId, state) =>
    parentRecordId
      ? relatedRecordCustomPropertySelector(
          record,
          parentRecordId,
          'search',
          '',
        )(state, {})
      : recordCustomPropertySelector(record, 'search', '')(state, {}),
)
