export const makeDebounce = (delay = 250) => {
  let timeout

  return {
    clear: () => clearTimeout(timeout),
    debounce: fn => {
      clearTimeout(timeout)
      timeout = setTimeout(fn, delay)
    },
  }
}
