import * as yup from 'yup'
import { hasValue } from '.'

const urlValidation = yup
  .string()
  .url()
  .required()

const emailValidation = yup
  .string()
  .email()
  .required()

export const isValidUrl = (url: string) => urlValidation.isValidSync(url)

export const isValidEmail = (email: string) =>
  emailValidation.isValidSync(email)

export const getIMSUrl = (url?: string) => {
  if (!url || !hasValue(url)) {
    return ''
  }
  return url.includes('http') ? url : `https://www.imsglobal.org${url}`
}
