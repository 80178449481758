import * as records from './records'
import * as baseUtils from './utils'
import * as address from './utils/address'
import * as array from './utils/array'
import * as date from './utils/date'
import * as debounce from './utils/debounce'
import * as env from './utils/env'
import * as features from './utils/features'
import * as files from './utils/files'
import * as host from './utils/host'
import * as localStorage from './utils/localStorage'
import * as sessionStorage from './utils/sessionStorage'
import * as sort from './utils/sort'
import * as string from './utils/strings'
import * as url from './utils/url'
import * as wait from './utils/wait'

const utils = {
  ...baseUtils,
  address,
  array,
  date,
  debounce,
  env,
  features,
  files,
  host,
  localStorage,
  sessionStorage,
  sort,
  string,
  url,
  wait,
}

export { records, utils }
