import * as R from 'ramda'

export const REMOVE_ENTITY = '@@entities/REMOVE_ENTITY'
interface IRemoveEntityPayload {
  entityKey: string
  key: string
}
interface IRemoveEntity {
  type: typeof REMOVE_ENTITY
  payload: IRemoveEntityPayload
}
export const removeEntity = (
  entityKey: string,
  key: string,
): IRemoveEntity => ({
  type: REMOVE_ENTITY,
  payload: {
    entityKey,
    key,
  },
})

export const CLEAR_ENTITIES = '@@entities/CLEAR_ENTITIES'
interface IClearEntitiesPayload {
  entityKey: string
}
interface IClearEntities {
  type: typeof CLEAR_ENTITIES
  payload: IClearEntitiesPayload
}
export const clearEntities = (entityKey: string): IClearEntities => ({
  type: CLEAR_ENTITIES,
  payload: {
    entityKey,
  },
})

export const REPLACE_ENTITIES = '@@entities/REPLACE_ENTITIES'
interface IReplaceEntitiesPayload {
  entities: object
}
interface IReplaceEntities {
  type: typeof REPLACE_ENTITIES
  payload: IReplaceEntitiesPayload
}
export const replaceEntities = (entities: object): IReplaceEntities => ({
  type: REPLACE_ENTITIES,
  payload: {
    entities,
  },
})

export const MERGE_ENTITIES = '@@entities/MERGE_ENTITIES'
interface IMergeEntitiesPayload {
  entities: object
}
interface IMergeEntities {
  type: typeof MERGE_ENTITIES
  payload: IMergeEntitiesPayload
}
export const mergeEntities = (entities: object): IMergeEntities => ({
  type: MERGE_ENTITIES,
  payload: {
    entities,
  },
})

export const UPDATE_ENTITY_PROPERTY = '@@entities/UPDATE_ENTITY_PROPERTY'
interface IUpdateEntityPropertyPayload {
  path: (string | number)[]
  updater: (currentValue: any) => any
}
interface IUpdateEntityProperty {
  type: typeof UPDATE_ENTITY_PROPERTY
  payload: IUpdateEntityPropertyPayload
}
export const updateEntityProperty = (
  path: (string | number)[],
  updater: (currentValue: any) => any,
): IUpdateEntityProperty => ({
  type: UPDATE_ENTITY_PROPERTY,
  payload: {
    path,
    updater,
  },
})

export const REPLACE_ENTITY_PROPERTY = '@@entities/REPLACE_ENTITY_PROPERTY'
interface IReplaceEntityPropertyPayload {
  path: (string | number)[]
  property: any
}
interface IReplaceEntityProperty {
  type: typeof REPLACE_ENTITY_PROPERTY
  payload: IReplaceEntityPropertyPayload
}
export const replaceEntityProperty = (
  path: (string | number)[],
  property: any,
): IReplaceEntityProperty => ({
  type: REPLACE_ENTITY_PROPERTY,
  payload: {
    path,
    property,
  },
})

export type EntitiesActions =
  | IRemoveEntity
  | IClearEntities
  | IReplaceEntities
  | IReplaceEntities
  | IMergeEntities
  | IUpdateEntityProperty
  | IReplaceEntityProperty

const initialState = {}

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case REMOVE_ENTITY:
      return R.dissocPath([action.payload.entityKey, action.payload.key], state)
    case CLEAR_ENTITIES:
      return R.dissoc(action.payload.entityKey, state)
    case REPLACE_ENTITIES:
      return R.mergeDeepWith(
        (_, newValue) => newValue,
        state,
        action.payload.entities,
      )
    case MERGE_ENTITIES:
      return R.mergeDeepRight(state, action.payload.entities)
    case UPDATE_ENTITY_PROPERTY: {
      const { path, updater } = action.payload
      return R.assocPath(path, updater(R.pathOr(null, path, state)), state)
    }
    case REPLACE_ENTITY_PROPERTY: {
      const { path, property } = action.payload
      return R.assocPath(path, property, state)
    }
    default:
      return state
  }
}
