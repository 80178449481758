import * as R from 'ramda'
import { createSelector } from 'reselect'

import { ensureArray } from '../../utils/array'
import { IEntitiesState, IEntityState } from '../ducks/entities.reducer'

export type getIdType =
  | number
  | string
  | (string | number)[]
  | ((state: any, props: any) => any)
export const idSelector = (getId: getIdType) => (state: any, props: any) => {
  if (!getId) {
    return null
  }

  if (R.is(Number, getId)) {
    return getId
  }

  if (R.is(String, getId)) {
    return R.prop(getId as string, props)
  }

  if (R.is(Array, getId)) {
    return R.path(getId as (string | number)[], props)
  }

  return (getId as (state: any, props: any) => any)(state, props)
}

export const entitiesSelector = R.propOr({}, 'entities')

export const entityListSelector = (entityKey: string) =>
  createSelector<any, IEntitiesState, IEntityState>(
    entitiesSelector,
    R.propOr({}, entityKey),
  )

export const entityCountSelector = (entityKey: string) =>
  createSelector<any, IEntityState, number>(
    entityListSelector(entityKey),
    entities => R.length(R.keys(entities)),
  )

export const entityByIdSelector = R.curryN(
  2,
  (entityKey: string, getId: getIdType, fallback: any = null) =>
    createSelector<any, any, any, IEntityState, any>(
      idSelector(getId),
      entityListSelector(entityKey),
      R.propOr(fallback),
    ),
)

export const entityPropSelector = (
  entityKey: string,
  getId: getIdType,
  prop: number | string | (string | number)[],
  fallBack: any = null,
) =>
  createSelector<any, any, any>(
    entityByIdSelector(entityKey, getId),
    R.pathOr(fallBack, ensureArray(prop)),
  )
