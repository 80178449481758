import * as R from 'ramda'

export interface IFeatureMap {
  [feature: string]: boolean
}

const ENABLED_FEATURES: IFeatureMap = {}

export const isFeatureEnabled = (
  feature: string,
  features: IFeatureMap = ENABLED_FEATURES,
): boolean => R.propOr<boolean, any, boolean>(false, feature, features)
