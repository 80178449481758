export const UPDATE_RECORD_CUSTOM_PROPERTY =
  '@@records/UPDATE_RECORD_CUSTOM_PROPERTY'
interface IUpdateRecordCustomPropertyPayload {
  record: string
  path: (string | number)[]
  updater: (currentValue?: any) => any
}
interface IUpdateRecordCustomProperty {
  type: typeof UPDATE_RECORD_CUSTOM_PROPERTY
  payload: IUpdateRecordCustomPropertyPayload
}
export const updateRecordCustomProperty = (
  record: string,
  path: (string | number)[],
  updater: (currentValue: any) => any,
): IUpdateRecordCustomProperty => ({
  type: '@@records/UPDATE_RECORD_CUSTOM_PROPERTY',
  payload: {
    record,
    path,
    updater,
  },
})

export const MERGE_RECORD_CUSTOM_PROPERTIES =
  '@@records/MERGE_RECORD_CUSTOM_PROPERTIES'
interface IMergeRecordCustomPropertiesPayload {
  record: string
  customProperties: object
}
interface IMergeRecordCustomProperties {
  type: typeof MERGE_RECORD_CUSTOM_PROPERTIES
  payload: IMergeRecordCustomPropertiesPayload
}
export const mergeRecordCustomProperties = (
  record: string,
  customProperties: object,
): IMergeRecordCustomProperties => ({
  type: '@@records/MERGE_RECORD_CUSTOM_PROPERTIES',
  payload: {
    record,
    customProperties,
  },
})

export const SET_RECORD_PROPERTIES = '@@records/SET_RECORD_PROPERTIES'
interface ISetRecordPropertiesPayload {
  record: string
  properties: object
}
interface ISetRecordProperties {
  type: typeof SET_RECORD_PROPERTIES
  payload: ISetRecordPropertiesPayload
}
export const setRecordProperties = (
  record: string,
  properties: object,
): ISetRecordProperties => ({
  type: SET_RECORD_PROPERTIES,
  payload: {
    record,
    properties,
  },
})

export const UPDATE_RECORD_PROPERTY = '@@records/UPDATE_RECORD_PROPERTY'
interface IUpdateRecordPropertyPayload {
  record: string
  path: (string | number)[]
  updater: (currentValue: any) => any
}
interface IUpdateRecordProperty {
  type: typeof UPDATE_RECORD_PROPERTY
  payload: IUpdateRecordPropertyPayload
}
export const updateRecordProperty = (
  record: string,
  path: (string | number)[],
  updater: (currentValue: any) => any,
): IUpdateRecordProperty => ({
  type: UPDATE_RECORD_PROPERTY,
  payload: {
    record,
    path,
    updater,
  },
})

export const REPLACE_RECORD_ITEMS = '@@records/REPLACE_RECORD_ITEMS'
interface IReplaceRecordItemsPayload {
  record: string
  items: number[]
}
interface IReplaceRecordItems {
  type: typeof REPLACE_RECORD_ITEMS
  payload: IReplaceRecordItemsPayload
}
export const replaceRecordItems = (
  record: string,
  items: number[],
): IReplaceRecordItems => ({
  type: REPLACE_RECORD_ITEMS,
  payload: {
    record,
    items,
  },
})

export const MERGE_RECORD_ITEMS = '@@records/MERGE_RECORD_ITEMS'
interface IMergeRecordItemsPayload {
  record: string
  items: number[]
}
interface IMergeRecordItems {
  type: typeof MERGE_RECORD_ITEMS
  payload: IMergeRecordItemsPayload
}
export const mergeRecordItems = (record, items): IMergeRecordItems => ({
  type: MERGE_RECORD_ITEMS,
  payload: {
    record,
    items,
  },
})

export const DELETE_RECORD_ITEM = '@@records/DELETE_RECORD_ITEM'
interface IDeleteRecordItemPayload {
  record: string
  item: number | string
}
interface IDeleteRecordItem {
  type: typeof DELETE_RECORD_ITEM
  payload: IDeleteRecordItemPayload
}
export const deleteRecordItem = (
  record: string,
  item: number | string,
): IDeleteRecordItem => ({
  type: DELETE_RECORD_ITEM,
  payload: {
    record,
    item,
  },
})

export type RecordActions =
  | IUpdateRecordCustomProperty
  | IMergeRecordCustomProperties
  | ISetRecordProperties
  | IUpdateRecordProperty
  | IReplaceRecordItems
  | IMergeRecordItems
  | IDeleteRecordItem
