import * as R from 'ramda'
import { createSelector } from 'reselect'

import { ensureArray } from '../../utils/array'
import { entityByIdSelector, entityListSelector, getIdType } from './entities'
import { defaultRecordState, IRecordState } from '../ducks/records.reducer'

export const recordSelector = (record: string) =>
  R.pathOr(defaultRecordState, ['records', record])

export const recordPropertyByIdSelector = (
  record: string,
  propertyPath: string | number | (string | number)[],
  fallback: any = null,
  getId: getIdType = 'id',
) =>
  createSelector<any, object, any, any>(
    entityByIdSelector(record, getId),
    R.pathOr(fallback, ensureArray(propertyPath)),
  )

export const allRecordsSelector = (record: string) =>
  createSelector<any, object, IRecordState, object, any[]>(
    recordSelector(record),
    entityListSelector(record),
    ({ items = [] }, entities) => items.map(id => entities[id]),
  )

export const recordPropertySelector = (
  record: string,
  propertyPath: string | number | (string | number)[],
  fallback: any = null,
) =>
  createSelector<any, object, any, any>(
    recordSelector(record),
    R.pathOr(fallback, ensureArray(propertyPath)),
  )

export const recordCustomPropertySelector = (
  record: string,
  propertyPath: string | number | (string | number)[],
  fallback: any = null,
) =>
  recordPropertySelector(
    record,
    R.prepend('customProperties', ensureArray(propertyPath)),
    fallback,
  )

// Convenience selectors
export const recordIsLoadingSelector = (record: string) =>
  recordPropertySelector(record, 'loading', false)

export const fullRecordsSelector = (record: string) =>
  createSelector<any, object, any[], IRecordState, IRecordState>(
    allRecordsSelector(record),
    recordSelector(record),
    (items, recordState) => R.assoc('items', items, recordState),
  )
