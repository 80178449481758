import * as R from 'ramda'

export const camelWrap = (str: string) =>
  str.replace(/([a-z])([A-Z])/g, found =>
    found.replace(/([a-z])([A-Z])/g, '$1\u200B$2'),
  )

export const ellipsize = (str: string, size: number, tail = '...') =>
  str.length < size
    ? str
    : `${R.slice(0, size - R.length(R.split('', tail)), str)}${tail}`

export const removeAllWhitespace = (str: string) => str.replace(/\W/g, '')

export const DOT_SEPARATOR = ' • '

export const joinString = (parts, separator = DOT_SEPARATOR) =>
  parts.filter(x => !!x).join(separator)

export const getIndicesOfWord = (
  searchStr: string,
  str: string,
  caseSensitive = true,
) => {
  const searchStrLen = searchStr.length
  if (searchStrLen === 0) {
    return []
  }
  let startIndex = 0
  const indices: number[] = []

  if (!caseSensitive) {
    str = str.toLowerCase()
    searchStr = searchStr.toLowerCase()
  }

  let index = str.indexOf(searchStr, startIndex)
  while (index > -1) {
    indices.push(index)
    startIndex = index + searchStrLen
    index = str.indexOf(searchStr, startIndex)
  }

  return indices
}

export const getFirstLastNameFromFullName = (fullName: string) => {
  if (fullName.split(' ').length < 2) {
    return [fullName, '']
  }
  const firstName = fullName
    .split(' ')
    .slice(0, -1)
    .join(' ')
  const lastName = fullName
    .split(' ')
    .slice(-1)
    .join(' ')
  return [firstName, lastName]
}
