import * as R from 'ramda'
import * as recordActions from './records.actions'
import { ensureArray } from '../../utils/array'

export interface IRecordPaging {
  nextPage?: boolean | number
  page?: number
  pageSize?: number
  total?: number
  totalPages?: number
  params?: object
}
export interface IRecordState {
  status?: number
  loading: boolean
  loadingNext: boolean
  refreshing: boolean
  items: number[] | any[]
  paging: IRecordPaging
  customProperties: object
}
export interface ITypedRecordState<T> {
  status?: number
  loading: boolean
  loadingNext: boolean
  refreshing: boolean
  items: T[]
  paging: object
  customProperties: object
}
export const defaultRecordState: IRecordState = {
  loading: true,
  loadingNext: false,
  refreshing: false,
  items: [],
  paging: {},
  customProperties: {},
}

export interface IRecordsState {
  [record: string]: IRecordState
}

const withDefaultState = R.mergeDeepRight(defaultRecordState)

const actionTypeUpdater = {
  [recordActions.UPDATE_RECORD_CUSTOM_PROPERTY]: (
    state: IRecordState,
    { path, updater },
  ) => R.over(R.lensPath(R.prepend('customProperties', path)), updater, state),
  [recordActions.MERGE_RECORD_CUSTOM_PROPERTIES]: (
    state,
    { customProperties },
  ) =>
    R.evolve(
      {
        customProperties: R.mergeDeepLeft(customProperties),
      },
      state,
    ),
  [recordActions.SET_RECORD_PROPERTIES]: (state, { properties }) =>
    R.mergeDeepRight(state, properties),
  [recordActions.UPDATE_RECORD_PROPERTY]: (state, { updater, path }) =>
    R.over(R.lensPath(path), updater, state),
  [recordActions.REPLACE_RECORD_ITEMS]: (state, { items }) =>
    R.assoc('items', items, state),
  [recordActions.MERGE_RECORD_ITEMS]: (state, { items }) =>
    R.evolve(
      {
        items: prevItems => R.uniq([...prevItems, ...ensureArray(items)]),
      },
      state,
    ),
  [recordActions.DELETE_RECORD_ITEM]: (state, { item }) =>
    R.evolve(
      {
        items: R.reject(id => id == item), // eslint-disable-line
      },
      state,
    ),
}

interface IRecordActionPayload {
  record: string
}
interface IRecordAction {
  type: string
  payload: IRecordActionPayload
}
export const isRecordAction = (action: object) =>
  !!R.path(['payload', 'record'], action)
export const recordReducer = (
  state: IRecordState = defaultRecordState,
  action: IRecordAction,
) => {
  const updater = actionTypeUpdater[action.type]

  return updater ? withDefaultState(updater(state, action.payload)) : state
}

export const reducer = (
  state: IRecordsState = {},
  action: recordActions.RecordActions,
) => {
  if (!isRecordAction(action)) {
    return state
  }
  const { record } = action.payload
  const recordState = R.propOr<IRecordState, IRecordsState, IRecordState>(
    defaultRecordState,
    record,
    state,
  )

  return R.has(action.type, actionTypeUpdater)
    ? R.assoc(record, recordReducer(recordState, action), state)
    : state
}
