import * as R from 'ramda'
import { hasValue } from '.'

export interface IAddress {
  streetAddress1?: string
  streetAddress2?: string
  city?: string
  state?: string
  country?: string
}
export const formatFullAddress = (address: IAddress) => {
  const secondLine = R.join(
    ' ',
    R.filter(hasValue, [
      address.state && address.city ? `${address.city},` : address.city,
      address.state,
    ]),
  )

  return [
    address.streetAddress1 || '',
    hasValue(address.streetAddress2) ? `\r\n${address.streetAddress2}` : '',
    hasValue(secondLine) ? `\r\n${secondLine}` : '',
    hasValue(address.country) ? `\r\n${address.country}` : '',
  ].join('')
}
