export const getReturnValuesBySortDirection = (sortAscending: boolean) =>
  sortAscending ? [-1, 1] : [1, -1]

export const ASC_ORDER = 'ascending'
export const DESC_ORDER = 'descending'
export const getSortValue = (sortAscending: boolean, inverse = false) =>
  sortAscending
    ? inverse
      ? DESC_ORDER
      : ASC_ORDER
    : inverse
    ? ASC_ORDER
    : DESC_ORDER

export const addSortValue = (
  interpolate: string,
  inverse = false,
  interpolateOn: string = '@',
) => (sortAscending: boolean) =>
  interpolate.replace(interpolateOn, `:${getSortValue(sortAscending, inverse)}`)
