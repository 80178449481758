import * as R from 'ramda'
import { getEnvVariable } from './env'

export const LOCALHOST = 'localhost'
export const PRODUCTION_HOST = 'app.1edtech.org'
export const STAGING_HOST = 'staging.1edtech.org'
export const DEV_HOST = 'dev.1edtech.net'
export const TEST_HOST = 'test.1edtech.net'

export const getHostname = () =>
  R.pathOr('N/A', ['location', 'hostname'], window)
export const isOnHost = (host: string | string[]) => {
  const hostname = getHostname()
  const hosts = Array.isArray(host) ? host : [host]
  return R.contains(hostname, hosts)
}

export const isRunningOnProduction = isOnHost(PRODUCTION_HOST)
export const isRunningOnStaging = isOnHost(STAGING_HOST)
export const isInProdMode = isRunningOnProduction || isRunningOnStaging
export const isInTestMode = getEnvVariable('TEST', process.env.TEST) || false
export const isInDevMode = process.env.NODE_ENV === 'development'

export const isRunningOnDev = isOnHost(DEV_HOST)
export const isRunningOnTest = isOnHost(TEST_HOST)
export const isInCIMode = !!process.env.CI
export const isRunningLocally = isOnHost(LOCALHOST)

export const isOnUrl = (urlPart: string, isExact = false) => {
  const pathname = R.pathOr('', ['location', 'pathname'], window).toLowerCase()
  if (isExact) {
    return urlPart.toLowerCase() === pathname
  }

  return pathname.includes(urlPart)
}
