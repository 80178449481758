import * as recordActions from './records.actions'

export const DELETE_RELATED_RECORD_ITEM =
  '@@relatedRecords/DELETE_RELATED_RECORD_ITEM'
export const MERGE_RELATED_RECORD_ITEMS =
  '@@relatedRecords/MERGE_RELATED_RECORD_ITEMS'
export const REPLACE_RELATED_RECORD_ITEMS =
  '@@relatedRecords/REPLACE_RELATED_RECORD_ITEMS'
export const SET_RELATED_RECORD_PROPERTIES =
  '@@relatedRecords/SET_RELATED_RECORD_PROPERTIES'
export const UPDATE_RELATED_RECORD_PROPERTY =
  '@@relatedRecords/UPDATE_RELATED_RECORD_PROPERTY'
export const MERGE_RELATED_RECORD_CUSTOM_PROPERTIES =
  '@@relatedRecords/MERGE_RELATED_RECORD_CUSTOM_PROPERTIES'
export const UPDATE_RELATED_RECORD_CUSTOM_PROPERTY =
  '@@relatedRecords/UPDATE_RELATED_RECORD_CUSTOM_PROPERTY'

interface IRecordMap {
  [key: string]: string
}
export const recordActionMap: IRecordMap = {
  [DELETE_RELATED_RECORD_ITEM]: recordActions.DELETE_RECORD_ITEM,
  [MERGE_RELATED_RECORD_ITEMS]: recordActions.MERGE_RECORD_ITEMS,
  [REPLACE_RELATED_RECORD_ITEMS]: recordActions.REPLACE_RECORD_ITEMS,
  [SET_RELATED_RECORD_PROPERTIES]: recordActions.SET_RECORD_PROPERTIES,
  [UPDATE_RELATED_RECORD_PROPERTY]: recordActions.UPDATE_RECORD_PROPERTY,
  [UPDATE_RELATED_RECORD_CUSTOM_PROPERTY]:
    recordActions.UPDATE_RECORD_CUSTOM_PROPERTY,
  [MERGE_RELATED_RECORD_CUSTOM_PROPERTIES]:
    recordActions.MERGE_RECORD_CUSTOM_PROPERTIES,
}

interface IUpdateRelatedRecordCustomPropertyPayload {
  record: string
  path: (string | number)[]
  updater: (currentValue: any) => any
  parentRecordId?: number | string
}
interface IUpdateRelatedRecordCustomProperty {
  type: typeof UPDATE_RELATED_RECORD_CUSTOM_PROPERTY
  payload: IUpdateRelatedRecordCustomPropertyPayload
}
export const updateRelatedRecordCustomProperty = (
  record: string,
  path: (string | number)[],
  updater: (currentValue: any) => any,
  parentRecordId?: number | string,
): IUpdateRelatedRecordCustomProperty => ({
  type: UPDATE_RELATED_RECORD_CUSTOM_PROPERTY,
  payload: {
    record,
    path,
    updater,
    parentRecordId,
  },
})

interface IMergeRelatedRecordCustomPropertiesPayload {
  record: string
  customProperties: object
  parentRecordId?: number | string
}
interface IMergeRelatedRecordCustomProperties {
  type: typeof MERGE_RELATED_RECORD_CUSTOM_PROPERTIES
  payload: IMergeRelatedRecordCustomPropertiesPayload
}
export const mergeRelatedRecordCustomProperties = (
  record: string,
  customProperties: object,
  parentRecordId?: number | string,
): IMergeRelatedRecordCustomProperties => ({
  type: MERGE_RELATED_RECORD_CUSTOM_PROPERTIES,
  payload: {
    record,
    customProperties,
    parentRecordId,
  },
})

interface ISetRelatedRecordPropertiesPayload {
  record: string
  properties: object
  parentRecordId?: number | string
}
interface ISetRelatedRecordProperties {
  type: typeof SET_RELATED_RECORD_PROPERTIES
  payload: ISetRelatedRecordPropertiesPayload
}
export const setRelatedRecordProperties = (
  record: string,
  properties: object,
  parentRecordId?: number | string,
): ISetRelatedRecordProperties => ({
  type: SET_RELATED_RECORD_PROPERTIES,
  payload: {
    record,
    properties,
    parentRecordId,
  },
})

interface IUpdateRelatedRecordPropertyPayload {
  record: string
  parentRecordId?: number | string
  path: (string | number)[]
  updater: (currentValue: any) => any
}
interface IUpdateRelatedRecordProperty {
  type: typeof UPDATE_RELATED_RECORD_PROPERTY
  payload: IUpdateRelatedRecordPropertyPayload
}
export const updateRelatedRecordProperty = (
  record: string,
  path: (string | number)[],
  updater: (currentValue: any) => any,
  parentRecordId?: number | string,
): IUpdateRelatedRecordProperty => ({
  type: UPDATE_RELATED_RECORD_PROPERTY,
  payload: {
    record,
    path,
    updater,
    parentRecordId,
  },
})

interface IReplaceRelatedRecordItemsPayload {
  record: string
  items: (string | number)[]
  parentRecordId?: number | string
}
interface IReplaceRelatedRecordItems {
  type: typeof REPLACE_RELATED_RECORD_ITEMS
  payload: IReplaceRelatedRecordItemsPayload
}
export const replaceRelatedRecordItems = (
  record: string,
  items: (string | number)[],
  parentRecordId?: number | string,
): IReplaceRelatedRecordItems => ({
  type: REPLACE_RELATED_RECORD_ITEMS,
  payload: {
    record,
    items,
    parentRecordId,
  },
})

interface IMergeRelatedRecordItemsPayload {
  record: string
  items: (string | number)[]
  parentRecordId?: number | string
}
interface IMergeRelatedRecordItems {
  type: typeof MERGE_RELATED_RECORD_ITEMS
  payload: IMergeRelatedRecordItemsPayload
}
export const mergeRelatedRecordItems = (
  record: string,
  items: (string | number)[],
  parentRecordId?: number | string,
): IMergeRelatedRecordItems => ({
  type: MERGE_RELATED_RECORD_ITEMS,
  payload: {
    record,
    parentRecordId,
    items,
  },
})

interface IDeleteRelatedRecordItemPayload {
  record: string
  item: string | number
  parentRecordId?: number | string
}
interface IDeleteRelatedRecordItem {
  type: typeof DELETE_RELATED_RECORD_ITEM
  payload: IDeleteRelatedRecordItemPayload
}
export const deleteRelatedRecordItem = (
  record: string,
  item: string | number,
  parentRecordId?: number | string,
): IDeleteRelatedRecordItem => ({
  type: DELETE_RELATED_RECORD_ITEM,
  payload: {
    record,
    parentRecordId,
    item,
  },
})

export type RelatedRecordActions =
  | IUpdateRelatedRecordCustomProperty
  | IMergeRelatedRecordCustomProperties
  | ISetRelatedRecordProperties
  | IUpdateRelatedRecordProperty
  | IReplaceRelatedRecordItems
  | IMergeRelatedRecordItems
  | IDeleteRelatedRecordItem
