import * as R from 'ramda'

import * as entitiesActions from './entities.actions'

export interface IEntityState {
  [id: string]: any
}
export interface IEntitiesState {
  [entity: string]: IEntityState | object
}

export const reducer = (
  state: IEntitiesState = {},
  action: entitiesActions.EntitiesActions,
): IEntitiesState => {
  switch (action.type) {
    case entitiesActions.REMOVE_ENTITY:
      return R.dissocPath([action.payload.entityKey, action.payload.key], state)
    case entitiesActions.CLEAR_ENTITIES:
      return R.dissoc(action.payload.entityKey, state)
    case entitiesActions.REPLACE_ENTITIES:
      return R.mergeDeepWith(
        (_, newValue) => newValue,
        state,
        action.payload.entities,
      )
    case entitiesActions.MERGE_ENTITIES:
      return R.mergeDeepRight(state, action.payload.entities)
    case entitiesActions.UPDATE_ENTITY_PROPERTY: {
      const { path, updater } = action.payload
      return R.assocPath(path, updater(R.pathOr(null, path, state)), state)
    }
    case entitiesActions.REPLACE_ENTITY_PROPERTY: {
      const { path, property } = action.payload
      return R.assocPath(path, property, state)
    }
    default:
      return state
  }
}
