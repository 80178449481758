import path from 'path'
import * as R from 'ramda'

export const ONE_GB_SIZE = 1024 * 1024 * 1024

export const isFileLargerThan = R.curry(
  (maxSize: number, fileSize: number) => fileSize >= maxSize,
)

export const isFileLargerThanOneGB = (fileSize: number) =>
  fileSize >= ONE_GB_SIZE

export const ZIP_EXTENSION = '.zip'
export const hasFileExtension = (name: string, ext: string) =>
  path.extname(name) === ext
