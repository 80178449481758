import * as R from 'ramda'
import { AxiosResponse } from 'axios'

import { convertToInt, hasValue } from '../../utils/index'
import { IModule } from './IModule'

export interface IStringIndexSignature {
  [key: string]: any
}

export interface INumberIndexSignature {
  [key: number]: any
  [key: string]: any
}

export const getRecordDataFromResponse = (
  recordModule: IModule,
  response: AxiosResponse,
  type?: 'GET' | 'CREATE' | 'PUT' | 'DELETE',
) => {
  if (recordModule.getResponseData) {
    return recordModule.getResponseData(response)
  }
  if (type === 'CREATE' && recordModule.getCreateResponseData) {
    return recordModule.getCreateResponseData(response)
  }
  if (recordModule.noResponseDataKey) {
    return response.data
  }
  if (recordModule.responseDataKey) {
    if (Array.isArray(recordModule.responseDataKey)) {
      if (
        R.pathSatisfies(
          hasValue,
          ['data', recordModule.responseDataKey[0]],
          response,
        )
      ) {
        return R.pathOr(
          null,
          ['data', recordModule.responseDataKey[0]],
          response,
        )
      }
      return R.pathOr(null, ['data', recordModule.responseDataKey[1]], response)
    }
    return R.pathOr(null, ['data', recordModule.responseDataKey], response)
  }
  return R.pathOr(response.data || {}, ['data', recordModule.record], response)
}

export const fixIdAttribute = (
  entity: IStringIndexSignature | INumberIndexSignature[],
): any => {
  const isArray = Array.isArray(entity)
  let newEntity = isArray
    ? [...(entity as INumberIndexSignature[])]
    : { ...entity }
  Object.keys(entity).forEach(k => {
    const key = isArray ? (convertToInt(k) as number) : (k as string)
    const value = isArray
      ? (entity as INumberIndexSignature)[convertToInt(k) as number]
      : (entity as IStringIndexSignature)[k as string]
    if (R.is(Array, value)) {
      if (isArray) {
        newEntity.splice(key, 1, fixIdAttribute(value))
      } else {
        newEntity = R.over(
          R.lensPath([key as number]),
          fixIdAttribute,
          newEntity,
        )
      }
    } else if (R.is(Object, value)) {
      if (isArray) {
        newEntity.splice(key, 1, fixIdAttribute(value))
      } else {
        newEntity = R.over(R.lensProp(key as string), fixIdAttribute, newEntity)
      }
    } else if (key === 'id') {
      newEntity = R.over(R.lensProp(key), convertToInt, newEntity)
    }
  })
  // eslint-disable-next-line
  return newEntity
}

export const getIdKey = R.join('-')

export const getIdAttributeByKeys = (keys: string[]) =>
  R.compose<object, object, string[], string>(
    R.join('-'),
    R.values,
    R.pick(keys),
  )

export const getSchemaOptions = (attributeKeys?: string[]) => ({
  idAttribute: attributeKeys ? getIdAttributeByKeys(attributeKeys) : 'id',
  processStrategy: fixIdAttribute,
})
